import en from '../translations/en.json';
import es from '../translations/es.json';
import ca from '../translations/ca.json';
import nl from '../translations/nl.json';
import nn from '../translations/nn.json';
import sv from '../translations/sv.json';
import fi from '../translations/fi.json';
import ar from '../translations/ar.json';
import zh from '../translations/zh.json';
import ru from '../translations/ru.json';
import pt from '../translations/pt.json';
import it from '../translations/it.json';
import de from '../translations/de.json';
import fr from '../translations/fr.json';
import tr from '../translations/tr.json';
import ja from '../translations/ja.json';
import da from '../translations/da.json';
import pl from '../translations/pl.json';

export const translations = {
  en,
  es,
  ca,
  nl,
  nn,
  sv,
  fi,
  ar,
  zh,
  ru,
  pt,
  it,
  de,
  fr,
  tr,
  ja,
  da,
  pl
};
