import { translations } from './loadTranslations';

const lngs = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Castellano' },
  ca: { nativeName: 'Català' },
  pl: { nativeName: 'Polski' },
  da: { nativeName: 'Dansk' },
  ja: { nativeName: '日本語' },
  tr: { nativeName: 'Türkçe' },
  fr: { nativeName: 'Français' },
  de: { nativeName: 'Deutsch' },
  it: { nativeName: 'Italiano' },
  pt: { nativeName: 'Português' },
  ru: { nativeName: 'Русский' },
  zh: { nativeName: '中文' },
  ar: { nativeName: 'العربية' },
  fi: { nativeName: 'Suomi' },
  nl: { nativeName: 'Nederlands' },
  sv: { nativeName: 'Svenska' },
  nn: { nativeName: 'Norsk' }
};

const loadJson = async (lng) => {
  try {
    console.log('Loading translation:', lng, translations[lng]);
    const response = await fetch(translations[lng]);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to load translation:', error);
    return {};
  }
};

const initializeI18next = async (initialLanguage) => {
  // Use only 2 first characters of initial language, map en-GB to en
  let language = initialLanguage.slice(0, 2);
  if (!lngs[language]) {
    language = 'en';
  }

  console.log('Initializing i18next with language:', initialLanguage, language);
  const initialTranslation = await loadJson(language);
  const resources = {
    [language]: {
      translation: initialTranslation
    }
  };

  await i18next
    .use(i18nextBrowserLanguageDetector)
    .init({
      debug: true,
      fallbackLng: 'en',
      resources,
      lng: initialLanguage
    });

  // Force language change to trigger translation loading
  i18next.changeLanguage(initialLanguage, () => {
    jqueryI18next.init(i18next, $, { useOptionsAttr: true });

    // Fill language switcher
    Object.keys(lngs).forEach((lng) => {
      const opt = new Option(lngs[lng].nativeName, lng);
      if (lng === i18next.resolvedLanguage) {
        opt.setAttribute("selected", "selected");
      }
      $('#languageSwitcher').append(opt);
    });

    let languageChangedCounter = 0;
    $('#languageSwitcher').change(async function() {
      const chosenLng = $(this).find("option:selected").attr('value');
      if (!i18next.hasResourceBundle(chosenLng, 'translation')) {
        const newTranslation = await loadJson(chosenLng);
        i18next.addResourceBundle(chosenLng, 'translation', newTranslation);
      }
      i18next.changeLanguage(chosenLng, () => {
        $('body').localize();
        languageChangedCounter++;
        $('#languageChangedNotification').localize({ count: languageChangedCounter });
        if (languageChangedCounter === 1) {
          $('#languageChangedNotification').show();
        }
      });
    });

    $('body').localize();

    $('p[data-i18n="explanation_content"]').each(function() {
      var $this = $(this);
      var content = $this.html();
      content = content.replace(/(turn your dreams into realities|AI|Law of Attraction|manifest|visualize|FEEL|convertir tus sueños en realidades|Ley de la atracción|manifiesta|visualizar|SENTIR)/g, '<b>$1</b>');
      $this.html(content);
    });
  });
};

$(document).ready(async function() {
  await i18next.use(i18nextBrowserLanguageDetector).init({
    fallbackLng: 'en',
    resources: {}
  });

  const detectedLanguage = i18next.language || 'en';
  const initialTranslation = await loadJson(detectedLanguage);
  i18next.addResourceBundle(detectedLanguage, 'translation', initialTranslation);
  await initializeI18next(detectedLanguage);

  const firebaseConfig = {
    apiKey: "AIzaSyCNE6bLJeYl5ttFsm7f__GhURpxeifpsLc",
    authDomain: "nevilize-prod.firebaseapp.com",
    projectId: "nevilize-prod",
    storageBucket: "nevilize-prod.appspot.com",
    messagingSenderId: "240623701762",
    appId: "1:240623701762:web:477c3aacc5e223b1bbfc1b",
    measurementId: "G-KM3SQKMPZ0"
  };

  firebase.initializeApp(firebaseConfig);

  function validateEmail(email) {
    var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  }

  function submitEmail() {
    const email = document.getElementById("email").value;
    const emailResponse = document.getElementById("emailResponse");

    if (!validateEmail(email)) {
      emailResponse.textContent = "Please enter a valid email address.";
      return;
    }

    const db = firebase.firestore();
    const emailsRef = db.collection("emails");

    emailsRef.where("email", "==", email).get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          emailResponse.textContent = "This email is already registered.";
          return;
        }

        emailsRef.add({
          email: email,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => {
          emailResponse.textContent = "Thank you! We'll notify you on launch.";
          document.getElementById("email").value = "";

          setTimeout(() => {
            emailResponse.textContent = "";
          }, 5000); // 5 seconds
        })
        .catch((error) => {
          emailResponse.textContent = "Oops! Something went wrong. Please try again.";
          console.error("Error adding email: ", error);
        });
      })
      .catch((error) => {
        emailResponse.textContent = "Error checking the email. Please try again.";
        console.error("Error checking email: ", error);
      });
  }

  $(".email__button").click(function() {
    submitEmail();
  });

  function gtag_report_conversion(url, conversionType) {
    var send_to = conversionType === 'Google Play' ? 'AW-16457972759/dFdaCOPd8ZUZEJf44qc9' : 'AW-16457972759/2t12CODd8ZUZEJf44qc9';
    var callback = function() {
      if (typeof(url) !== 'undefined') {
        window.location = url;
      }
    };
    gtag('event', 'conversion', {
      'send_to': send_to,
      'event_callback': callback
    });
    return false;
  }

  $('.app__link[href*="apps.apple.com"]').on('click', function(event) {
    event.preventDefault();
    var url = $(this).attr('href');
    console.log('App Store link clicked: ' + url);
    gtag_report_conversion(url, 'App Store');
  });

  $('.app__link[href*="play.google.com"]').on('click', function(event) {
    event.preventDefault();
    var url = $(this).attr('href');
    console.log('Google Play link clicked: ' + url);
    gtag_report_conversion(url, 'Google Play');
  });

  !function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

    ttq.load('CN7KH8BC77U706OO4A70');
    ttq.page();
  }(window, document, 'ttq');

  // function checkDeviceAndRedirect() {
  //   var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //   if (localStorage.getItem("hasVisited") !== "true") {
  //     if (/android/i.test(userAgent)) {
  //       localStorage.setItem("hasVisited", "true");
  //       window.location.href = "https://play.google.com/store/apps/details?id=com.nevilize.nevilize";
  //     } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //       localStorage.setItem("hasVisited", "true");
  //       window.location.href = "https://apps.apple.com/app/apple-store/id6451499200?pt=125982962&ct=Nevilize.com&mt=8";
  //     }
  //   }
  // }

  // checkDeviceAndRedirect();
});
